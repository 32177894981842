<template>
  <div class="cat-placeholder">
    <div class="container">
    
      <div v-for="(i, index) in cardItems" :key="index">
        <div class="component-title-placeholder">
          <div class="title-container loading" v-if="showTitlePlace"></div>
          <div class="title-container loading" v-if="showTitlePlace"></div>
        </div>
        <div class="skeleton-container">
          <div  v-for="(i, index) in new Array(7) " :class="{
                'skeleton': true,
                'loading': true,
                'skeleton-square': screen.displayType === 'SQUARE',
                'skeleton-portraits': screen.displayType === 'PORTRAIT',
                'skeleton-landscape': screen.displayType == 'LANDSCAPE'
              }"></div>
        </div>
        <div class="mobile-skeleton-container">
          <div class="skeleton-items">
            <div class="skeleton loading"></div>
            <div class="skeleton loading"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    screen: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      showTitlePlace: true,
      cardItems: 1
    };
  },
  mounted() {
    if (this.$route.name == "detailComponent") {
      this.showTitlePlace = false;
    }
  }
}
</script>

<style lang="scss">
@import "@/sass/_components.scss";
@import "./categoriesPlaceholder.scss";
</style>
